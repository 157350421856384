import React from "react"
import {StaticImage} from 'gatsby-plugin-image'
import Layout from "../layout/layout"
import * as colors from '../colors.module.css'
import { CardHeader, MediumParagraph } from "../typography"
import { FullWidthVerticallyCenteredCard } from "../components/cards"
import * as styles from './thank-you.module.css'

const SignupThankYou = ({ location }) => {
  React.useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-810784927/Y8e8CLehh4ADEJ-xzoID'
      });
    }
  }, []);

  return (
    <Layout excludeDonateAndSignup>
      <FullWidthVerticallyCenteredCard >
        <CardHeader className={colors.navy}>Thank You!</CardHeader>
        <div className={styles.cardContents}>
          <StaticImage src="../images/our-beliefs-smiling-kids-flexing.png" alt="smiling kids flexing muscles" />
          <div>
            <MediumParagraph>Thanks for signing up! Check your inbox to confirm your subscription to exciting updates from our programs</MediumParagraph>
          </div>
        </div>
      </FullWidthVerticallyCenteredCard>
    </Layout>
  )
}

export default SignupThankYou
